/**
 * Created by henian.xu on 2019/10/31.
 * 项目路由
 */
import { addPower, addRoutes } from 'vmf';
import Api from '@/api';
import { ElementUI } from '@vmf/components';
import demoRoutes from './demo';
import LayoutMessageMenu from '@/Layout/messageMenu/Menu';
import { Menu as LayoutMenu } from '@/Layout';
import { getSocket } from '@/plugins/WebSocket';

const { Loading } = ElementUI;

const MenuProps = {
    verifyMenu: false, // 是否校验菜单权限
    data: {},
    asideMenuChange: item => {
        Api.Lg.MenuVisitLog.log({ menuUrl: item.url });
        console.log('asideMenuChange', item);
    },
}; // 菜单布局组件的 数据

// 复用页面
const rsSetUser = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/user.vue');
const rsSetRole = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/role.vue');
const rsSetOrg = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/org.vue');
// 服务商管理-列表
const svSvmgServant = () => import(/* webpackChunkName: "sv-svmg" */ '@/pages/sv/svmg/servant.vue');
// 服务商管理-编辑
const svSvmgServantEdit = () => import(/* webpackChunkName: "sv-svmg" */ '@/pages/sv/svmg/servantEdit.vue');
// 服务商信息
const svSvmgServantInfo = () => import(/* webpackChunkName: "sv-svmg" */ '@/pages/sv/svmg/servantInfo.vue');

addRoutes(
    [
        {
            path: '/',
            component: LayoutMessageMenu,
            children: [
                {
                    path: '/',
                    component: LayoutMenu,
                    props: MenuProps,
                    children: [
                        // ...OthersRoutes,
                        ...demoRoutes,
                        {
                            path: '/index',
                            name: 'index',
                            component: () => import('@/pages/index.vue'),
                            meta: {
                                title: '首页',
                            },
                        },
                        // ---平台路由
                        ...[
                            // 系统管理
                            ...[
                                // 系统设置
                                ...[
                                    {
                                        path: '/rs/set/org',
                                        name: 'rs-set-org',
                                        component: rsSetOrg,
                                        meta: {
                                            title: '部门管理',
                                        },
                                    },
                                    {
                                        path: '/rs/set/role',
                                        name: 'rs-set-role',
                                        component: rsSetRole,
                                        meta: {
                                            title: '角色管理',
                                        },
                                    },
                                    {
                                        path: '/rs/set/variable',
                                        name: 'rs-set-variable',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/variable.vue'),
                                        meta: {
                                            title: '系统变量',
                                        },
                                    },
                                    {
                                        path: '/rs/set/region',
                                        name: 'rs-set-region',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/region.vue'),
                                        meta: {
                                            title: '区域管理',
                                        },
                                    },
                                    {
                                        path: '/rs/set/menu',
                                        name: 'rs-set-menu',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/menu.vue'),
                                        meta: {
                                            title: '菜单管理',
                                        },
                                    },
                                    {
                                        path: '/rs/set/user',
                                        name: 'rs-set-user',
                                        component: rsSetUser,
                                        meta: {
                                            title: '用户管理',
                                        },
                                    },
                                    {
                                        path: '/rs/set/app',
                                        name: 'rs-set-app',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/app.vue'),
                                        meta: {
                                            title: '应用管理',
                                        },
                                    },
                                    {
                                        path: '/rs/set/dic',
                                        name: 'rs-set-dic',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/dic.vue'),
                                        meta: {
                                            title: '字典管理',
                                        },
                                    },
                                    {
                                        path: '/rs/set/express',
                                        name: 'rs-set-express',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/express.vue'),
                                        meta: {
                                            title: '快递公司管理',
                                        },
                                    },
                                ],
                                // 多语言配置管理
                                ...[
                                    {
                                        path: '/rs/language/languageModule',
                                        name: 'rs-language-languageModule',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ 'pages/rs/language/languageModule.vue'),
                                        meta: {
                                            title: '多语言配置模块',
                                        },
                                    },
                                    {
                                        path: '/rs/language/languageEntry',
                                        name: 'rs-language-languageEntry',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ 'pages/rs/language/languageEntry.vue'),
                                        meta: {
                                            title: '多语言条目管理',
                                        },
                                    },
                                    {
                                        path: '/rs/language/languageEntry/edit/:id?',
                                        name: 'rs-language-languageEntry-edit',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ 'pages/rs/language/languageEntryEdit.vue'),
                                        meta: {
                                            title: '编辑多语言条目',
                                            parentUrl: '/rs/language/languageEntry',
                                        },
                                    },
                                    {
                                        path: '/rs/language/languageDataConf',
                                        name: 'rs-language-languageDataConf',
                                        component: () =>
                                            import(/* webpackChunkName: "rs-set" */ '@/pages/rs/language/languageDataConf.vue'),
                                        meta: {
                                            title: '数据多语言配置',
                                        },
                                    },
                                ],
                            ],
                            // 平台运营
                            ...[
                                // 平台设置
                                ...[
                                    {
                                        path: '/pfom/set/wxmsgOrg',
                                        name: 'pfom-set-wxmsgOrg',
                                        component: () =>
                                            import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/wxmsgOrg.vue'),
                                        meta: {
                                            title: '微信消息模板',
                                        },
                                    },
                                    {
                                        path: '/pfom/set/wxmsgTemplate/:id?',
                                        name: 'pfom-set-wxmsgTemplate',
                                        component: () =>
                                            import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/wxmsgTemplate.vue'),
                                        meta: {
                                            title: '微信消息模板',
                                            parentUrl: '/pfom/set/wxmsgOrg',
                                        },
                                    },
                                    {
                                        path: '/pfom/set/smsOrg',
                                        name: 'pfom-set-smsOrg',
                                        component: () =>
                                            import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsOrg.vue'),
                                        meta: {
                                            title: '短信模板',
                                        },
                                    },
                                    {
                                        path: '/pfom/set/smsTemplate/:id?',
                                        name: 'pfom-set-smsTemplate',
                                        component: () =>
                                            import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsTemplate.vue'),
                                        meta: {
                                            title: '短信模板',
                                            parentUrl: '/pfom/set/smsOrg',
                                        },
                                    },
                                    {
                                        path: '/pfom/set/smsAccount',
                                        name: 'pfom-set-smsAccount',
                                        component: () =>
                                            import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsAccount'),
                                        meta: {
                                            title: '短信设置',
                                        },
                                    },
                                ],
                            ],
                            // 服务商管理
                            ...[
                                // 服务商管理
                                ...[
                                    {
                                        path: '/pfsv/svmg/servant',
                                        name: 'pfsv-svmg-servant',
                                        component: svSvmgServant,
                                        meta: {
                                            title: '服务商管理',
                                        },
                                    },
                                    {
                                        path: '/pfsv/svmg/servant/edit/:id?',
                                        name: 'pfsv-svmg-servant-edit',
                                        component: svSvmgServantEdit,
                                        meta: {
                                            title: '服务商管理',
                                            parentUrl: '/pfsv/svmg/servant',
                                        },
                                    },
                                    {
                                        path: '/pfsv/svmg/servant/paymentConfig/:id?',
                                        name: 'pfsv-svmg-servant-paymentConfig',
                                        component: () =>
                                            import(/* webpackChunkName: "pfom-set" */ '@/pages/sv/svmg/servantPaymentConfig.vue'),
                                        meta: {
                                            title: '服务商支付管理',
                                            parentUrl: '/pfsv/svmg/servant',
                                        },
                                    },
                                ],
                            ],
                        ],
                        // ---服务商路由
                        ...[
                            // 服务商管理
                            ...[
                                // 权限管理
                                ...[
                                    {
                                        path: '/svsv/set/org',
                                        name: 'svsv-set-org',
                                        component: rsSetOrg,
                                        meta: {
                                            title: '部门管理',
                                        },
                                    },
                                    {
                                        path: '/svsv/set/role',
                                        name: 'svsv-set-role',
                                        component: rsSetRole,
                                        meta: {
                                            title: '角色管理',
                                        },
                                    },
                                    {
                                        path: '/svsv/set/user', // 复用 rs-set-user .vue
                                        name: 'svsv-set-user',
                                        component: rsSetUser,
                                        meta: {
                                            title: '用户管理',
                                        },
                                    },
                                ],
                                // 服务商信息
                                ...[
                                    {
                                        path: '/svsv/svmg/servantInfo',
                                        name: 'svsv-svmg-servantInfo',
                                        component: svSvmgServantInfo,
                                        meta: {
                                            title: '服务商信息',
                                        },
                                    },
                                ],
                                // 店铺装修
                                ...[
                                    {
                                        path: '/sv/decoration/homeDecoration',
                                        name: 'sv-decoration-homeDecoration',
                                        component: () =>
                                            import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/homeDecoration.vue'),
                                        meta: {
                                            title: '首页装修',
                                        },
                                    },
                                    {
                                        path: '/sv/decoration/activityDecoration',
                                        name: 'sv-decoration-activityDecoration',
                                        component: () =>
                                            import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/activityDecoration.vue'),
                                        meta: {
                                            title: '活动装修',
                                        },
                                    },
                                    {
                                        path: '/sv/decoration/adPageDecoration',
                                        name: 'sv-decoration-adPageDecoration',
                                        component: () =>
                                            import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/adPageDecoration.vue'),
                                        meta: {
                                            title: '广告位装修',
                                        },
                                    },
                                    // 装修编辑
                                    {
                                        path: '/sv/decoration/home/edit/:id?',
                                        name: 'sv-decoration-home-edit',
                                        component: () =>
                                            import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/edit.vue'),
                                        meta: {
                                            title: '首页装修',
                                            parentUrl: '/sv/decoration/homeDecoration',
                                        },
                                    },
                                    {
                                        path: '/sv/decoration/activity/edit/:id?',
                                        name: 'sv-decoration-activity-edit',
                                        component: () =>
                                            import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/edit.vue'),
                                        meta: {
                                            title: '活动装修',
                                            parentUrl: '/sv/decoration/activityDecoration',
                                        },
                                    },
                                    {
                                        path: '/sv/decoration/adPage/edit/:id?',
                                        name: 'sv-decoration-adPage-edit',
                                        component: () =>
                                            import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/edit.vue'),
                                        meta: {
                                            title: '广告位装修',
                                            parentUrl: '/sv/decoration/adPageDecoration',
                                        },
                                    },
                                    {
                                        path: '/pt/portal/portalInfoConfig',
                                        name: 'pt-portal-portalInfoConfig',
                                        component: () =>
                                            import(/* webpackChunkName: "sv-decoration" */ '@/pages/pt/portal/portalInfoConfig.vue'),
                                        meta: {
                                            title: '平台配置',
                                        },
                                    },
                                ],
                                // 日志管理
                                ...[
                                    {
                                        path: '/lg/log/menuVisitLog',
                                        name: 'lg-log-menuVisitLog',
                                        component: () =>
                                            import(/* webpackChunkName: "lg-svmg" */ '@/pages/lg/log/menuVisitLog.vue'),
                                        meta: {
                                            title: '访问日志',
                                        },
                                    },
                                ],
                            ],
                            // CMS管理
                            ...[
                                // CMS分类
                                ...[
                                    {
                                        path: '/pt/cms/cmsCatalog',
                                        name: 'pt-cms-cmsCatalog',
                                        component: () =>
                                            import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsCatalog'),
                                        meta: {
                                            title: '文章分类',
                                        },
                                    },
                                ],
                                // CMS文章
                                ...[
                                    {
                                        path: '/pt/cms/cmsArticle',
                                        name: 'pt-cms-cmsArticle',
                                        component: () =>
                                            import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsArticle'),
                                        meta: {
                                            title: '文章列表',
                                        },
                                    },
                                    {
                                        path: '/pt/cms/cmsArticle/edit/:cmsArticleType?/:id?',
                                        name: 'pt-cms-cmsArticle-edit',
                                        component: () =>
                                            import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsArticleEdit.vue'),
                                        meta: {
                                            title: '文章列表',
                                            parentUrl: '/pt/cms/cmsArticle',
                                        },
                                    },
                                    {
                                        path: '/pt/cms/cmsArticle/comment/:id?',
                                        name: 'pt-cms-cmsArticle-comment',
                                        component: () =>
                                            import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsArticleComment.vue'),
                                        meta: {
                                            title: '文章评论',
                                            parentUrl: '/pt/cms/cmsArticle',
                                        },
                                    },
                                ],
                            ],
                            // 课程管理
                            ...[
                                {
                                    path: '/ce/course/courseCat',
                                    name: 'ce-course-courseCat',
                                    component: () => import('@/pages/ce/course/courseCat.vue'),
                                    meta: {
                                        title: '课程分类',
                                    },
                                },
                                {
                                    path: '/ce/course/course',
                                    name: 'ce-course-course',
                                    component: () => import('@/pages/ce/course/course.vue'),
                                    meta: {
                                        title: '课程列表',
                                    },
                                },
                                {
                                    path: '/ce/course/course/edit/:id?',
                                    name: 'ce-course-course-edit',
                                    component: () => import('@/pages/ce/course/courseEdit.vue'),
                                    meta: {
                                        title: '课程详情',
                                        parentUrl: '/ce/course/course',
                                    },
                                },
                                {
                                    path: '/ce/course/course/comment/:id?',
                                    name: 'ce-course-course-comment',
                                    component: () => import('@/pages/ce/course/courseComment.vue'),
                                    meta: {
                                        title: '课程评论',
                                        parentUrl: '/ce/course/course',
                                    },
                                },
                                {
                                    path: '/ce/course/courseMember',
                                    name: 'ce-course-courseMember',
                                    component: () => import('@/pages/ce/course/courseMember.vue'),
                                    meta: {
                                        title: '学习列表',
                                    },
                                },
                            ],
                            // 积分管理
                            ...[
                                {
                                    path: '/mb/point/pointRule',
                                    name: 'mb-point-pointRule',
                                    component: () =>
                                        import(/* webpackChunkName: "mb-point" */ '@/pages/mb/point/pointRule.vue'),
                                    meta: {
                                        title: '积分场景',
                                    },
                                },
                            ],
                            // 活动管理
                            ...[
                                {
                                    path: '/mb/activity/online/activityCat',
                                    name: 'mb-activity-online-activityCat',
                                    component: () => import('@/pages/mb/activity/online/activityCat.vue'),
                                    meta: {
                                        title: '线上活动分类',
                                    },
                                },
                                {
                                    path: '/mb/activity/offline/activityCat',
                                    name: 'mb-activity-offline-activityCat',
                                    component: () => import('@/pages/mb/activity/offline/activityCat.vue'),
                                    meta: {
                                        title: '线下活动分类',
                                    },
                                },
                                {
                                    path: '/mb/activity/online/activity',
                                    name: 'mb-activity-online-activity',
                                    component: () => import('@/pages/mb/activity/online/activity.vue'),
                                    meta: {
                                        title: '线上活动',
                                    },
                                },
                                {
                                    path: '/mb/activity/online/activity/edit/:id?',
                                    name: 'mb-activity-online-activity-edit',
                                    component: () => import('@/pages/mb/activity/online/activityEdit.vue'),
                                    meta: {
                                        title: '编辑线上活动',
                                        parentUrl: '/mb/activity/online/activity',
                                    },
                                },
                                {
                                    path: '/mb/activity/online/activity/view/:id?',
                                    name: 'mb-activity-online-activity-view',
                                    component: () => import('@/pages/mb/activity/online/activityView.vue'),
                                    meta: {
                                        title: '查看线上活动',
                                        parentUrl: '/mb/activity/online/activity',
                                    },
                                },
                                {
                                    path: '/mb/activity/online/activity/comment/:id?',
                                    name: 'mb-activity-online-activity-comment',
                                    component: () => import('@/pages/mb/activity/activityComment.vue'),
                                    meta: {
                                        title: '评论列表',
                                        parentUrl: '/mb/activity/online/activity',
                                    },
                                },
                                {
                                    path: '/mb/activity/online/activity/member/:id?',
                                    name: 'mb-activity-online-activity-member',
                                    component: () => import('@/pages/mb/activity/activityMember.vue'),
                                    meta: {
                                        title: '报名列表',
                                        parentUrl: '/mb/activity/online/activity',
                                    },
                                },
                                {
                                    path: '/mb/activity/offline/activity',
                                    name: 'mb-activity-offline-activity',
                                    component: () => import('@/pages/mb/activity/offline/activity.vue'),
                                    meta: {
                                        title: '线下活动',
                                    },
                                },
                                {
                                    path: '/mb/activity/offline/activity/edit/:id?',
                                    name: 'mb-activity-offline-activity-edit',
                                    component: () => import('@/pages/mb/activity/offline/activityEdit.vue'),
                                    meta: {
                                        title: '编辑线下活动',
                                        parentUrl: '/mb/activity/offline/activity',
                                    },
                                },
                                {
                                    path: '/mb/activity/offline/activity/view/:id?',
                                    name: 'mb-activity-offline-activity-view',
                                    component: () => import('@/pages/mb/activity/offline/activityView.vue'),
                                    meta: {
                                        title: '查看线下活动',
                                        parentUrl: '/mb/activity/offline/activity',
                                    },
                                },
                                {
                                    path: '/mb/activity/offline/activity/comment/:id?',
                                    name: 'mb-activity-offline-activity-comment',
                                    component: () => import('@/pages/mb/activity/activityComment.vue'),
                                    meta: {
                                        title: '评论列表',
                                        parentUrl: '/mb/activity/offline/activity',
                                    },
                                },
                                {
                                    path: '/mb/activity/offline/activity/member/:id?',
                                    name: 'mb-activity-offline-activity-member',
                                    component: () => import('@/pages/mb/activity/activityMember.vue'),
                                    meta: {
                                        title: '报名列表',
                                        parentUrl: '/mb/activity/offline/activity',
                                    },
                                },
                            ],
                            // 基础信息
                            ...[
                                // 站点管理
                                ...[
                                    {
                                        path: '/pe/station/station',
                                        name: 'pe-station-station',
                                        component: () => import('@/pages/pe/station/station.vue'),
                                        meta: {
                                            title: '站点信息',
                                        },
                                    },
                                    {
                                        path: '/pe/station/station/edit/:id?',
                                        name: 'pe-station-station-edit',
                                        component: () => import('@/pages/pe/station/stationEdit.vue'),
                                        meta: {
                                            title: '编辑站点',
                                            parentUrl: '/pe/station/station',
                                        },
                                    },
                                    {
                                        path: '/pe/schedule/station/schedulingPlan',
                                        name: 'pe-schedule-station-schedulingPlan',
                                        component: () => import('@/pages/pe/schedule/station/schedulingPlan.vue'),
                                        meta: {
                                            title: '站点排班计划',
                                        },
                                    },
                                    {
                                        path: '/pe/schedule/station/schedulingPlan/edit/:id?',
                                        name: 'pe-schedule-station-schedulingPlan-edit',
                                        component: () => import('@/pages/pe/schedule/station/schedulingPlanEdit.vue'),
                                        meta: {
                                            title: '编辑站点排班计划',
                                            parentUrl: '/pe/schedule/station/schedulingPlan',
                                        },
                                    },
                                    {
                                        path: '/pe/schedule/station/schedulingPlan/view/:id',
                                        name: 'pe-schedule-station-schedulingPlan-view',
                                        component: () => import('@/pages/pe/schedule/station/schedulingPlanView.vue'),
                                        meta: {
                                            title: '查看站点排班计划',
                                            parentUrl: '/pe/schedule/station/schedulingPlan',
                                        },
                                    },
                                    {
                                        path: '/pe/schedule/station/schedule',
                                        name: 'pe-schedule-station-schedule',
                                        component: () => import('@/pages/pe/schedule/station/schedule.vue'),
                                        meta: {
                                            title: '站点日程表',
                                        },
                                    },
                                ],
                                // 设备管理
                                ...[
                                    {
                                        path: '/pe/device/device',
                                        name: 'pe-device-device',
                                        component: () => import('@/pages/pe/device/device.vue'),
                                        meta: {
                                            title: '设备信息',
                                        },
                                    },
                                    {
                                        path: '/pe/device/device/edit/:id?',
                                        name: 'pe-device-device-edit',
                                        component: () => import('@/pages/pe/device/deviceEdit.vue'),
                                        meta: {
                                            title: '编辑设备',
                                            parentUrl: '/pe/device/device',
                                        },
                                    },
                                ],
                                // 医生信息
                                ...[
                                    {
                                        path: '/pe/doctor/doctorDepartment',
                                        name: 'pe-doctor-doctorDepartment',
                                        component: () => import('@/pages/pe/doctor/doctorDepartment.vue'),
                                        meta: {
                                            title: '医生科室',
                                        },
                                    },
                                    {
                                        path: '/pe/doctor/doctor',
                                        name: 'pe-doctor-doctor',
                                        component: () => import('@/pages/pe/doctor/doctor.vue'),
                                        meta: {
                                            title: '医生信息',
                                        },
                                    },
                                    {
                                        path: '/pe/doctor/doctor/edit/:id?',
                                        name: 'pe-doctor-doctor-edit',
                                        component: () => import('@/pages/pe/doctor/doctorEdit.vue'),
                                        meta: {
                                            title: '编辑医生',
                                            parentUrl: '/pe/doctor/doctor',
                                        },
                                    },
                                    {
                                        path: '/pe/schedule/doctor/schedulingPlan',
                                        name: 'pe-schedule-doctor-schedulingPlan',
                                        component: () => import('@/pages/pe/schedule/doctor/schedulingPlan.vue'),
                                        meta: {
                                            title: '医生排班计划',
                                        },
                                    },
                                    {
                                        path: '/pe/schedule/doctor/schedulingPlan/edit/:id?',
                                        name: 'pe-schedule-doctor-schedulingPlan-edit',
                                        component: () => import('@/pages/pe/schedule/doctor/schedulingPlanEdit.vue'),
                                        meta: {
                                            title: '编辑医生排班计划',
                                            parentUrl: '/pe/schedule/doctor/schedulingPlan',
                                        },
                                    },
                                    {
                                        path: '/pe/schedule/doctor/schedulingPlan/view/:id',
                                        name: 'pe-schedule-doctor-schedulingPlan-view',
                                        component: () => import('@/pages/pe/schedule/doctor/schedulingPlanView.vue'),
                                        meta: {
                                            title: '查看医生排班计划',
                                            parentUrl: '/pe/schedule/doctor/schedulingPlan',
                                        },
                                    },
                                    {
                                        path: '/pe/schedule/doctor/schedule',
                                        name: 'pe-schedule-doctor-schedule',
                                        component: () => import('@/pages/pe/schedule/doctor/schedule.vue'),
                                        meta: {
                                            title: '医生日程表',
                                        },
                                    },
                                ],
                                // 体检项目管理
                                ...[
                                    {
                                        path: '/pe/physicalExam/physicalExamItem',
                                        name: 'pe-physicalExam-physicalExamItem',
                                        component: () => import('@/pages/pe/physicalExam/physicalExamItem.vue'),
                                        meta: {
                                            title: '体检项目',
                                        },
                                    },
                                    {
                                        path: '/pe/physicalExam/physicalExamItem/edit/:id?',
                                        name: 'pe-physicalExam-physicalExamItem-edit',
                                        component: () => import('@/pages/pe/physicalExam/physicalExamItemEdit.vue'),
                                        meta: {
                                            title: '编辑体检项目',
                                            parentUrl: '/pe/physicalExam/physicalExamItem',
                                        },
                                    },
                                    {
                                        path: '/pe/physicalExam/physicalExamItem/view/:id?',
                                        name: 'pe-physicalExam-physicalExamItem-view',
                                        component: () => import('@/pages/pe/physicalExam/physicalExamItemView.vue'),
                                        meta: {
                                            title: '查看体检项目',
                                            parentUrl: '/pe/physicalExam/physicalExamItem',
                                        },
                                    },
                                ],
                                // 志愿者管理
                                ...[
                                    {
                                        path: '/mb/member/memberVolunteer',
                                        name: 'mb-member-memberVolunteer',
                                        component: () => import('@/pages/mb/member/memberVolunteer.vue'),
                                        meta: {
                                            title: '志愿者',
                                        },
                                    },
                                    {
                                        path: '/mb/member/memberVolunteer/view/:id',
                                        name: 'mb-member-memberVolunteer-view',
                                        component: () => import('@/pages/mb/member/memberVolunteerView.vue'),
                                        meta: {
                                            title: '查看志愿者',
                                            parentUrl: '/mb/member/memberVolunteer',
                                        },
                                    },
                                ],
                                // 病例管理
                                ...[
                                    {
                                        path: '/pe/case/caseCat',
                                        name: 'pe-case-caseCat',
                                        component: () => import('@/pages/pe/case/caseCat.vue'),
                                        meta: {
                                            title: '病例分类',
                                        },
                                    },
                                    {
                                        path: '/pe/case/caseItem',
                                        name: 'pe-case-caseItem',
                                        component: () => import('@/pages/pe/case/caseItem.vue'),
                                        meta: {
                                            title: '病例列表',
                                        },
                                    },
                                    {
                                        path: '/pe/case/caseItem/edit/:id?',
                                        name: 'pe-case-caseItem-edit',
                                        component: () => import('@/pages/pe/case/caseItemEdit.vue'),
                                        meta: {
                                            title: '编辑病例',
                                            parentUrl: '/pe/case/caseItem',
                                        },
                                    },
                                ],
                                // 入驻管理
                                ...[
                                    {
                                        path: '/mb/member/memberSettlement',
                                        name: 'mb-member-memberSettlement',
                                        component: () => import('@/pages/mb/member/memberSettlement.vue'),
                                        meta: {
                                            title: '入驻管理',
                                        },
                                    },
                                    {
                                        path: '/mb/member/memberSettlement/view/:id',
                                        name: 'mb-member-memberSettlement-view',
                                        component: () => import('@/pages/mb/member/memberSettlementView.vue'),
                                        meta: {
                                            title: '入驻管理',
                                            parentUrl: '/mb/member/memberSettlement',
                                        },
                                    },
                                ],
                            ],
                            // 量表管理
                            ...[
                                {
                                    path: '/am/scale/healthyScale',
                                    name: 'am-scale-healthyScale',
                                    component: () =>
                                        import(/* webpackChunkName: "am-scale" */ '@/pages/am/scale/healthyScale.vue'),
                                    meta: {
                                        title: '健康量表管理',
                                    },
                                },
                                {
                                    path: '/am/scale/healthyScale/edit/:id?',
                                    name: 'am-scale-healthyScale-edit',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ '@/pages/am/scale/scaleEdit.vue'),
                                    meta: {
                                        title: '健康量表管理',
                                        parentUrl: '/am/scale/healthyScale',
                                    },
                                },
                                {
                                    path: '/am/scale/healthyScale/view/:id?',
                                    name: 'am-scale-healthyScale-view',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ '@/pages/am/scale/scaleView.vue'),
                                    meta: {
                                        title: '健康量表管理',
                                        parentUrl: '/am/scale/healthyScale',
                                    },
                                },
                                {
                                    path: '/am/scale/healthyScale/factor/:id?',
                                    name: 'am-scale-healthyScale-factor',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ '@/pages/am/scale/scaleFactorEdit.vue'),
                                    meta: {
                                        title: '健康量表管理',
                                        parentUrl: '/am/scale/healthyScale',
                                    },
                                },
                                {
                                    path: '/am/scale/psychologyScale',
                                    name: 'am-scale-psychologyScale',
                                    component: () =>
                                        import(/* webpackChunkName: "am-scale" */ '@/pages/am/scale/psychologyScale.vue'),
                                    meta: {
                                        title: '心理量表管理',
                                    },
                                },
                                {
                                    path: '/am/scale/psychologyScale/edit/:id?',
                                    name: 'am-scale-psychologyScale-edit',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ '@/pages/am/scale/scaleEdit.vue'),
                                    meta: {
                                        title: '心理量表管理',
                                        parentUrl: '/am/scale/psychologyScale',
                                    },
                                },
                                {
                                    path: '/am/scale/psychologyScale/view/:id?',
                                    name: 'am-scale-psychologyScale-view',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ '@/pages/am/scale/scaleView.vue'),
                                    meta: {
                                        title: '心理量表管理',
                                        parentUrl: '/am/scale/psychologyScale',
                                    },
                                },
                                {
                                    path: '/am/scale/psychologyScale/factor/:id?',
                                    name: 'am-scale-psychologyScale-factor',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ '@/pages/am/scale/scaleFactorEdit.vue'),
                                    meta: {
                                        title: '心理量表管理',
                                        parentUrl: '/am/scale/psychologyScale',
                                    },
                                },
                            ],
                            // 测评管理
                            ...[
                                {
                                    path: '/am/assessPlan/healthyPlan',
                                    name: 'am-assessPlan-healthyPlan',
                                    component: () =>
                                        import(/* webpackChunkName: "am-scale" */ 'pages/am/healthyPlan/healthyPlan.vue'),
                                    meta: {
                                        title: '健康问卷计划',
                                    },
                                },
                                {
                                    path: '/am/assessPlan/healthyPlan/edit/:id?',
                                    name: 'am-assessPlan-healthyPlan-edit',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ 'pages/am/healthyPlan/healthyPlanEdit.vue'),
                                    meta: {
                                        title: '健康问卷计划',
                                        parentUrl: '/am/assessPlan/healthyPlan',
                                    },
                                },
                                {
                                    path: '/am/assessPlan/psychologyPlan',
                                    name: 'am-assessPlan-psychologyPlan',
                                    component: () =>
                                        import(/* webpackChunkName: "am-scale" */ 'pages/am/psychologyPlan/psychologyPlan.vue'),
                                    meta: {
                                        title: '心理测评计划',
                                    },
                                },
                                {
                                    path: '/am/assessPlan/psychologyPlan/edit/:id?',
                                    name: 'am-assessPlan-psychologyPlan-edit',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ 'pages/am/psychologyPlan/psychologyPlanEdit.vue'),
                                    meta: {
                                        title: '心理测评计划',
                                        parentUrl: '/am/assessPlan/psychologyPlan',
                                    },
                                },
                                {
                                    path: '/am/assess/healthyAssess',
                                    name: 'am-assess-healthyAssess',
                                    component: () =>
                                        import(/* webpackChunkName: "am-scale" */ '@/pages/am/assess/healthyAssess.vue'),
                                    meta: {
                                        title: '健康问卷列表',
                                    },
                                },
                                {
                                    path: '/am/assess/healthyAssess/result/:id',
                                    name: 'am-assess-healthyAssess-result',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ '@/pages/am/assess/assessResult.vue'),
                                    meta: {
                                        title: '健康问卷列表',
                                        parentUrl: '/am/assess/healthyAssess',
                                    },
                                },
                                {
                                    path: '/am/assess/psychologyAssess',
                                    name: 'am-assess-psychologyAssess',
                                    component: () =>
                                        import(/* webpackChunkName: "am-scale" */ '@/pages/am/assess/psychologyAssess.vue'),
                                    meta: {
                                        title: '心理测评列表',
                                    },
                                },
                                {
                                    path: '/am/assess/psychologyAssess/result/:id',
                                    name: 'am-assess-psychologyAssess-result',
                                    component: () =>
                                        import(/* webpackChunkName: "am-svmg" */ '@/pages/am/assess/assessResult.vue'),
                                    meta: {
                                        title: '心理测评列表',
                                        parentUrl: '/am/assess/psychologyAssess',
                                    },
                                },
                            ],
                            // 心理咨询管理
                            ...[
                                {
                                    path: '/pe/doctor/doctorReservation',
                                    name: 'pe-doctor-doctorReservation',
                                    component: () =>
                                        import(/* webpackChunkName: "pe-scale" */ 'pages/pe/doctorReservation/doctorReservation.vue'),
                                    meta: {
                                        title: '心理咨询列表',
                                    },
                                },
                            ],
                            // 体检管理
                            ...[
                                {
                                    path: '/pe/medical/physicalExamReservation',
                                    name: 'pe-medical-physicalExamReservation',
                                    component: () =>
                                        import(/* webpackChunkName: "pe-scale" */ 'pages/pe/physicalExamReservation/physicalExamReservation.vue'),
                                    meta: {
                                        title: '体检预约列表',
                                    },
                                },
                                {
                                    path: '/pe/medicalReport/medicalReport',
                                    name: 'pe-medicalReport-medicalReport',
                                    component: () =>
                                        import(/* webpackChunkName: "pe-medicalReport" */ 'pages/pe/medicalReport/medicalReport.vue'),
                                    meta: {
                                        title: '体检报告列表',
                                    },
                                },
                            ],
                        ],
                    ],
                },
                ...demoRoutes,
            ],
        },
    ],
    async () => {
        console.log('初始路由数据开始', 111111);
        // TODO webSocket 复位不应该在这里 by henian.xu
        const socket = getSocket();
        if (socket) {
            socket.close();
        }
        const loadingInstance = Loading.service({
            lock: true,
            text: '正在加载所需数据,请稍后...',
        });
        const jsons = await Promise.all([Api.Rs.User.menu(), Api.Rs.User.menuResource()]).finally(() => {
            loadingInstance.close();
        });
        MenuProps.data = jsons[0].data.data;
        addPower(jsons[1].data.data);
    },
);

addRoutes([
    {
        path: '/largeScreen',
        name: 'largeScreen',
        component: () => import('@/pages/largeScreen/index.vue'),
        meta: {
            title: '智慧党建数据大屏',
        },
    },
    {
        path: '/login',
        name: 'login',
        // component: Pages.Login,
        component: () => import('@/pages/login.vue'),
        meta: {
            title: '登录',
            isChoose: false,
        },
    },
]);
