import { Pe } from './Pe';

class DoctorReservation extends Pe {
    constructor() {
        super();
        this.baseUrl += '/doctorReservation';
    }

    data({
        fullName,
        idCardNo,
        stationName,
        mobile,
        reservationType,
        reservationDate,
        reservationStatus,
        currentPage,
        pageSize,
    } = {}) {
        let reservationDateBegin, reservationDateEnd;
        if (reservationDate && reservationDate.length === 2) {
            [reservationDateBegin, reservationDateEnd] = reservationDate;
        }
        return super.get('/data', {
            fullName,
            idCardNo,
            stationName,
            mobile,
            reservationType,
            reservationDateBegin,
            reservationDateEnd,
            reservationStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', {
            id,
        });
    }
}
export default new DoctorReservation();
export { DoctorReservation };
