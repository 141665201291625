import { Mb } from './Mb';

class Activity extends Mb {
    constructor() {
        super();
        this.baseUrl += '/activity';
    }

    data({
        activityCatId,
        orgName,
        activityType,
        activityName,
        releaseTime,
        applyEndTime,
        regionId,
        contactFullName,
        contactTel,
        isNeedSignIn,
        activityStatus,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            activityCatId,
            orgName,
            activityType,
            activityName,
            releaseTime,
            applyEndTime,
            regionId,
            contactFullName,
            contactTel,
            isNeedSignIn,
            activityStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super
            .get('/get', {
                id,
            })
            .then(json => {
                const res = json.data.data;
                const {
                    fileBasePath,
                    activityImage,
                    activityAttachment,
                    activityTimeBegin,
                    activityTimeEnd,
                    addressLongitude,
                    addressLatitude,
                } = res;
                if (fileBasePath) {
                    if (activityImage) {
                        res.activityImage = activityImage.split(',').reduce((prev, curr) => {
                            prev.push({
                                name: curr,
                                address: fileBasePath + curr,
                            });
                            return prev;
                        }, []);
                    } else {
                        res.activityImage = [];
                    }
                    if (activityAttachment) {
                        res.activityAttachment = activityAttachment.split(',').reduce((prev, curr) => {
                            prev.push({
                                name: curr,
                                address: fileBasePath + curr,
                            });
                            return prev;
                        }, []);
                    } else {
                        res.activityAttachment = [];
                    }
                }

                if (activityTimeBegin && activityTimeEnd) {
                    res.activityTime = [activityTimeBegin, activityTimeEnd];
                }

                if (addressLongitude && addressLatitude) {
                    res.latLng = addressLongitude + ',' + addressLatitude;
                }

                return json;
            });
    }

    save({
        id,
        activityCatId,
        orgName,
        activityType,
        activityName,
        activityImage,
        activityDesc,
        activityAttachment,
        releaseTime,
        applyEndTime,
        activityTime,
        limitPeopleNum,
        limitPointNum,
        activityAmount,
        regionId,
        address,
        latLng,
        contactFullName,
        contactTel,
        isNeedSignIn,
        isSubmit,
    } = {}) {
        let activityImageUrl = '';
        if (activityImage && activityImage.length) {
            activityImageUrl = activityImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let activityAttachmentUrl = '';
        if (activityAttachment && activityAttachment.length) {
            activityAttachmentUrl = activityAttachment
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let activityTimeBegin;
        let activityTimeEnd;
        if (activityTime && activityTime.length === 2) {
            [activityTimeBegin, activityTimeEnd] = activityTime;
        }
        let addressLongitude, addressLatitude;
        if (latLng) {
            const latLngs = latLng.split(',').reduce((prev, curr) => {
                prev.push(curr);
                return prev;
            }, []);
            if (latLngs && latLngs.length === 2) {
                [addressLongitude, addressLatitude] = latLngs;
            }
        }
        return super.post('/save', {
            id,
            activityCatId,
            orgName,
            activityType,
            activityName,
            activityImage: activityImageUrl,
            activityDesc,
            activityAttachment: activityAttachmentUrl,
            releaseTime,
            applyEndTime,
            activityTimeBegin,
            activityTimeEnd,
            limitPeopleNum,
            limitPointNum,
            regionId,
            address,
            addressLongitude,
            addressLatitude,
            activityAmount,
            contactFullName,
            contactTel,
            isNeedSignIn,
            isSubmit,
        });
    }

    cancelActivity({ ids } = {}) {
        return super.post('/cancelActivity', {
            ids,
        });
    }

    getMinAppCodeImage({ id } = {}) {
        return super.get('/getMinAppCodeImage', {
            id,
        });
    }

    audit({ id, auditStatus, auditRemarks } = {}) {
        return super.post('/audit', {
            id,
            auditStatus,
            auditRemarks,
        });
    }
}
export default new Activity();
export { Activity };
